import { Text, View, StyleSheet } from 'react-native';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Input, Button, SelectPicker, Message, useToaster, List, MaskedInput, DatePicker} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import {useDropdownData } from "../../../hooks/useDropdownData";
import {set} from "react-hook-form";
import locale from "../../../tr";
import CustomText from "../../../Components/CustomText";
import {createNumberMask} from "text-mask-addons";

const FastEntryScreen = () => {
    const [plaka, setPlaka] = useState('');
    const [plakaError, setPlakaError] = useState(false);
    const [plakaErrorText, setPlakaErrorText] = useState('');
    const [entryType, setEntryType] = useState('');
    const [carData, setCarData] = useState(null);
    const toaster = useToaster();

    const { fetchIdToken } = useUserToken();
    const { getDropDownValuesGlobal } = useDropdownData();

    const is_numeric = (char) => !isNaN(char) && char !== ' ';
    const setCharAt = (str, index, replacement) =>
        str.substring(0, index) + replacement + str.substring(index + 1);

    const fixPlaka = (input) => {
        let plakatemp = input.toUpperCase();
        let letter_count = 0;

        for (let i = 0; i < plakatemp.length; i++) {
            if (!is_numeric(plakatemp.charAt(i)) && plakatemp.charAt(i) !== ' ') {
                letter_count++;
            }
        }

        if (plakatemp.length > 2 && plakatemp.charAt(2) !== ' ') {
            plakatemp = setCharAt(plakatemp, 1, plakatemp.charAt(1) + ' ');
        }

        if (letter_count === 1) {
            if (plakatemp.length > 4 && plakatemp.charAt(4) !== ' ' && !is_numeric(plakatemp.charAt(3))) {
                plakatemp = setCharAt(plakatemp, 3, plakatemp.charAt(3) + ' ');
            }
        } else if (letter_count === 2) {
            if (plakatemp.length > 5 && plakatemp.charAt(5) !== ' ' && !is_numeric(plakatemp.charAt(4))) {
                plakatemp = setCharAt(plakatemp, 4, plakatemp.charAt(4) + ' ');
            }
        } else {
            if (plakatemp.length > 6 && plakatemp.charAt(6) !== ' ') {
                plakatemp = setCharAt(plakatemp, 5, plakatemp.charAt(5) + ' ');
            }
        }

        // Prevent entering letters in the third section
        const parts = plakatemp.split(' ');
        if (parts.length > 2 && parts[2] && !is_numeric(parts[2][0])) {
            plakatemp = plakatemp.slice(0, -1);
        }

        // Enforce length limit of 11 characters (e.g., "34 AB 1234")
        if (plakatemp.length > 11) {
            plakatemp = plakatemp.slice(0, 11);
        }

        const result = isValidPlaka(plakatemp);
        if (!result.valid) {
            setPlakaError(true);
            setPlakaErrorText(result.error);
        } else {
            setPlakaError(false);
            setPlakaErrorText('');
        }
        return plakatemp;
    };

    const isValidPlaka = (plakatemp) => {
        const regex = /^[0-9]{2} [A-Z]{1,2} [0-9]{1,4}$/;
        if (!regex.test(plakatemp)) {
            return { valid: false, error: 'Format Hatası , Doğru Format: "12 ABC 1234".' };
        }
        return { valid: true, error: '' };
    };

    const handlePlateChange = (value) => {
        const formattedValue = fixPlaka(value);
        setPlaka(formattedValue);
    };

    const fetchCarData = async () => {
        if (!plaka) {
            toaster.push(
                <Message showIcon type="error">
                    Please enter a plate number.
                </Message>,
                { placement: 'topEnd' }
            );
            return;
        }

        const idToken = await fetchIdToken();
        const headers = {
            "Authorization": `Bearer ${idToken}`
        };

        const bodyData = {
            "PLAKA": plaka,
            "ENV": configdata.APP_ENV,
            "type": "GET_CAR_W_PLAKA"
        }

        const API_BASE = configdata.API_BASE + "/cardata";

        axios.post(API_BASE, bodyData, { headers }).then((response) => {
            console.log(response.data);
            if(response.data[0].PLAKA === undefined) {
                toaster.push(
                    <Message showIcon type="error">
                        Araç Bulunamadı
                    </Message>,
                    {placement: 'topEnd'}
                );
            }
            else {
                toaster.push(
                    <Message showIcon type="success">
                        Araç Bulundu Veri Girişi İçin Hazır
                    </Message>,
                    { placement: 'topEnd' }
                );
                setCarData(response.data[0]);

            }
        }
        ).catch((error) => {
            console.log(error);
            toaster.push(
                <Message showIcon type="error">
                    Araç Bulunamadı
                </Message>,
                { placement: 'topEnd' }
            );
        });


    };



    const handleSubmit = async (data) => {
        console.log("Sending data...");
        const idToken = await fetchIdToken();
        const headers = {
            'Authorization': `Bearer ${idToken}`
        }

        let kmFormatted = Number(data.KM.replace(' KM', '').replace('.', ''));
        let amountFormatted = Number(data.AMOUNT.replace('₺ ', '').replace('.', '').replace(',', '.'));
        if(entryType === "MAINTENANCE") {
            const bodyData = {
                "CAR_ID": carData.NO,
                "MAINTENANCE_KM": kmFormatted,
                "MAINTENANCE_TYPE": data.MAINTENANCE_TYPE,
                "SERVICE": data.SERVICE,
                "MAINTENANCE_DATE": data.MAINTENANCE_DATE,
                "AMOUNT": amountFormatted,
                "INVOICE_NO": data.INVOICE_NO,
                "ENV": configdata.APP_ENV,
                "NOTES": data.NOTE,
                "type": "NEW_MAINTENANCE_DOC"
            }

            console.log(bodyData);

            const API_BASE = configdata.API_BASE + "/cardata";
            axios.post(API_BASE, bodyData, { headers }).then((response) => {
                console.log(response.data);
                toaster.push(
                    <Message showIcon type="success">
                        Veri Başarıyla Eklendi
                    </Message>,
                    { placement: 'topEnd' }
                );

            }
            ).catch((error) => {
                console.log(error);
                toaster.push(
                    <Message showIcon type="error">
                        Veri Eklenemedi
                    </Message>,
                    { placement: 'topEnd' }
                );
            }
            );

            console.log(bodyData);
        }
    }

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Hızlı Veri Giriş Sistemi</Text>

            <Input
                label="Plate Number"
                style={styles.standard}
                value={plaka}
                onChange={handlePlateChange}
            />
            {plakaError && <Text style={styles.errorText}>{plakaErrorText}</Text>}

            <Button appearance="primary" onClick={fetchCarData}>
                Aracı Ara
            </Button>

            {/* Choosen Car infos such as Plate number şaseno engine araç ismi */}
            {
                carData &&
                <List>
                    <List.Item>Araç: {carData.ARAÇ}</List.Item>
                    <List.Item>Plaka: {carData.PLAKA}</List.Item>
                    <List.Item>Şasi: {carData.ŞASİ}</List.Item>
                </List>
            }

            <View style={{ height: 20 }} />

            <SelectPicker
                data={[
                    { label: "Bakım Girişi", value: "MAINTENANCE" },
                    { label: "KM Girişi", value: "KM_UPDATE" }
                ]}
                style={styles.standard}
                value={entryType}
                onChange={setEntryType}
                placeholder="Veri Giriş Tipi"
            />

            <View style={{ height: 20 }} />

            {   carData  &&  <RenderForm carData={carData} entryType={entryType} sendData={handleSubmit} getDropDownValuesGlobal={getDropDownValuesGlobal} /> }



        </View>
    );
};


const RenderForm = ({carData,entryType, sendData, getDropDownValuesGlobal}) => {

    const [KM, setKM] = useState('');
    const [maintenanceType, setMaintenanceType] = useState('');
    const [service, setService] = useState('');
    const [maintenanceDate, setMaintenanceDate] = useState('');
    const [amount, setAmount] = useState('');
    const [invoiceNo, setInvoiceNo] = useState('');
    const [note, setNote] = useState('');


    useEffect(() => {
        resetForm();
    }, [entryType,carData]);

    const [maintenanceTypes, setMaintenanceTypes] = useState([]);

    useEffect(() => {
        getDropDownValuesGlobal("MAINTENANCE_TYPE", setMaintenanceTypes);
    }, []);


    function resetForm() {
        setKM('');
        setMaintenanceType('');
        setService('');
        setMaintenanceDate('');
        setAmount('');
        setInvoiceNo('');
        setNote('');
    }


    if (entryType === "MAINTENANCE") {
        return (
            <View>
                <Group header="Kilometre">
                    <MaskedInput mask={kmMask} value={KM} style={styles.standard} onChange={ (value) => {setKM(value);}} name= {"KM"} />
                </Group>

                <Group header="Bakım Tipi">
                    <SelectPicker value={maintenanceType} style={styles.standard}  onSelect={(value) => { setMaintenanceType(value)}} data = {maintenanceTypes} ></SelectPicker>
                </Group>
                <Group header="Servis">
                    <Input value={service} style={styles.standard}  onChange={ (value) => {setService(value);}} name= {"service"} />
                </Group>
                <Group header="Bakım Tarihi">
                    <DatePicker  value={maintenanceDate} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                    {setMaintenanceDate(value);}}
                                 oneTap format="dd-MM-yyyy"
                                 locale={locale}
                    ></DatePicker>
                </Group>
                <Group header="Bakım Tutarı">
                    <MaskedInput value={amount} style={styles.standard} mask={numberMaskTl} onChange={ (value) => {setAmount(value);}} name= {"tutar"} />
                </Group>
                <Group header="Fatura No">
                    <Input value={invoiceNo} style={styles.standard}  onChange={ (value) => {setInvoiceNo(value);}} name= {"invoice"} />
                </Group>
                <View style={{ height: 20 }} />
                <Group header="Notlar">
                    <Input value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"}  style={styles.standard} />
                </Group>
                <Button appearance="primary" onClick={() => sendData(
                    {
                        "KM": KM,
                        "MAINTENANCE_TYPE": maintenanceType,
                        "SERVICE": service,
                        "MAINTENANCE_DATE": maintenanceDate,
                        "AMOUNT": amount,
                        "INVOICE_NO": invoiceNo,
                        "NOTE": note

                    }
                )}>
                    Veriyi Yükle
                </Button>
            </View>
        );
    } else if (entryType === "KM_UPDATE") {
        return (
            <View>
                <Input label="KM" style={styles.standard} />
            </View>
        );
    }

    return null;
}

const numberMaskTl = createNumberMask({
    prefix: '₺ ',
    suffix: '',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
});

const kmMask = createNumberMask(
    {
        prefix: '',
        suffix: ' KM',
        thousandsSeparatorSymbol :'.',
        allowDecimal : false,
        allowLeadingZeroes: false,
        integerLimit: 7,
        allowNegative: false
    }
)


const Group = ({ children, ...props }) => (
    <View {...props} style={styles.group}>
        <View style = {{ marginRight: 5 , flex: 1}}>
            <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
        </View>
        <View style={{flex: 4, alignItems: 'flex-end'}}>
            {children}
        </View>
    </View>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#f9f9f9',
    },
    group: {
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 3,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    standard: {
        marginBottom: 15,
        width: '100%',
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
});

export default FastEntryScreen;