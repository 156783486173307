import { Text, View ,ScrollView} from 'react-native'
import React, { useState ,useEffect } from 'react';
import { useToaster,Message,Button,Table,Column,Input, Container,IconButton, Modal, SelectPicker ,Loader} from 'rsuite';
import * as XLSX from 'xlsx';
import axios from 'axios';
import FileUploader from '../../Components/FileManagements/FileUploader';
import NewCarLabel from './NewCarLabel';
import configdata from '../../config/config.json';
import ExportData from '../../Components/TableManagement/ExportData';
import 'typeface-roboto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import  {useUserToken}  from '../../hooks/useUserToken';
import  {useUtils,useValidationRules} from '../../hooks/useUtils';
import  {useDropdownData} from '../../hooks/useDropdownData';


const MultipleCarUpdateScreen  = () => {
  

    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }

    const [wrongRows,setWrongRows] = useState([]);
    const [correctRows,setcorrectRows] = useState([]);


    //#region ComboBoxData
    const [optionsBMP,setOptionsBMP] = useState([]);
    const [carBodyStyles,setCarBodyStyles] = useState([]);
    const [factory_years,setfactory_years] = useState([]);
    const [fuel_types,setFuel_types] = useState([]);
    const [cc_types,setcc_types] = useState([]);
    const [carOwners,setCarOwners] = useState([]);
    const [colors,setColors] = useState([]);
    const [km_periods,setKm_periods] = useState([]);
    const [transmissions,settransmissions] = useState([]);


    const {getDropDownValues,getDropDownValuesGlobal,getDropDownValuesService,getBrands} = useDropdownData();
    const {validatePlateNumber, validateStandard ,ValidateBrandModelPaket} = useValidationRules();
    const {ExcelDateToJSDate} = useUtils();
    const {fetchIdToken} = useUserToken();


    useEffect(() => {
      console.log("useEffect");
      getBrands(setOptionsBMP);
      getDropDownValues("CAR_BODY_STYLES",setCarBodyStyles);
      getDropDownValues("SERVICE_KM_PERIOD",setKm_periods);
      getDropDownValues("TRANSMISSIONS",settransmissions);
      getDropDownValues("FUEL_TYPES",setFuel_types);
      getDropDownValues("CC_TYPES",setcc_types);
      getDropDownValuesGlobal("CAR_OWNER",setCarOwners);
      getDropDownValuesGlobal("COLOR",setColors);
      getDropDownValuesService("GET_LAST_50_YEARS",setfactory_years);
    },[])


    //#endregion


    const handleFileUpload = (file) => {
      // const file = e.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
  
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
  
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const headers = data[0];
        const rows = data.slice(1);
        const CRows = [];
        const fixNeededRows = [];

        const {SSF} = XLSX;
        let columnsTemp = [];
        headers.forEach((header, index) => {
          if (header !== undefined && header !== "CHECKED") {
              columnsTemp.push(
                { key: header, name: header }
              );
          }
        });
        setColumns(columnsTemp);
        // const validatedRows = 
        rows.map(row => {
        const rowObject = {};
        
        rowObject["Fix Needed"] = "";
          headers.forEach((header, index) => {
                rowObject[header] = row[index];
                if(Object.keys(validateNeedColumns).includes(header)){
                  if(validateNeedColumns[header].type === "LIST"){
                      let result = validateStandard(row[index],validateNeedColumns[header].list);
                      if(!result.isValid){
                        editFixNeed(rowObject,"Hatalı " + header);
                      }else{
                        rowObject[header] = result.label;
                        rowObject[header + "_RDS_ID"] = result.id;
                      }
                  }else if(validateNeedColumns[header].type === "PLAKA"){
                      let result = validatePlateNumber(row[index]);
                      if(!result.isValid){
                        editFixNeed(rowObject,"Hatalı " + header);
                      }else{
                        rowObject[header] = result.plateNumber;
                      }
                  }
                  else if(validateNeedColumns[header].type === "DATE"){
                    if(row[index] != undefined && row[index] != ""){
                        // console.log(row[index]);
                        rowObject[header] = ExcelDateToJSDate(row[index]);
                    }
                  }
                }
          });
          
          
          // Validate plate number, or any other parameters , send Validated to CRows and error to fixNeededRows

            if( rowObject["Fix Needed"] == undefined || rowObject["Fix Needed"] == null || rowObject["Fix Needed"] == ""){
              CRows.push(rowObject);
            }else{

              fixNeededRows.push(rowObject);
            }

          });
         
          setcorrectRows(CRows);
          setWrongRows(fixNeededRows);
        // setRows(validatedRows);
      };
  
      reader.readAsBinaryString(file);
    };


    function parseDate(dateString) {

      if(dateString != undefined && dateString != ""){
        if(dateString.split('-').length === 3){
          const [day, month, year] = dateString.split('-');
          return `${day}-${month}-${year}`;
        }else{
          const [day, month, year] = dateString.split('/');
          return  `${day}-${month}-${year}`;
        }
      }
      return "";
    }

    function parseDateFromExcel(input){
      
      if(input == null || input == undefined || input == ""){
        return  null;
      }
      if(new Date(input) != "Invalid Date"){
        return  parseDate(input.toLocaleDateString("en-US"));
      }
      return null;
    }

    const [isLoaded,setIsLoaded] = useState(false);
    const [ButtonPressed,setButtonPressed] = useState(false);

    useEffect(() => {  if(isLoaded) {  setButtonPressed(false);}},[isLoaded])
    const sendData = async ()  => {
        setButtonPressed(true);
        setIsLoaded(false);
        const exportData = [];
        correctRows.forEach((row) => {

          const header = {
            "TESCİL_TARİHİ": row["TESCİL TARİHİ"] == undefined ? null : row["TESCİL TARİHİ"],
            "MARKA": row["MARKA_RDS_ID"] == undefined ? null : row["MARKA_RDS_ID"],
            "MODEL": row["MODEL_RDS_ID"] == undefined ? null : row["MODEL_RDS_ID"], 
            "PAKET":  row["PAKET_RDS_ID"] == undefined ? null : row["PAKET_RDS_ID"],
            "PLAKA":  row["PLAKA"] == undefined ? null : row["PLAKA"],
            "KASA_TİPİ" : row["KASA_RDS_ID"] == undefined ? null : row["KASA_RDS_ID"],
            "BAKIM_KM_PERİYODU" : row["BAKIM KM PERİYODU_RDS_ID"] == undefined ? null : row["BAKIM KM PERİYODU_RDS_ID"],
            "VİTES_TİPİ" : row["VİTES_RDS_ID"] == undefined ? null : row["VİTES_RDS_ID"],
            "YAKIT_TİPİ" : row["YAKIT TİPİ_RDS_ID"] == undefined ? null : row["YAKIT TİPİ_RDS_ID"],
            "CC" : row["CC_RDS_ID"] == undefined ? null : row["CC_RDS_ID"],
            "MODEL_YILI" : row["MODEL YILI_RDS_ID"] == undefined ? null : row["MODEL YILI_RDS_ID"],
            "HP" : row["HP"] == undefined ? null : row["HP"],
            "ŞASİ_NO":  row["ŞASİ NO"] == undefined ? null : row["ŞASİ NO"],
            "MOTOR_NO":  row["MOTOR NO"] == undefined ? null : row["MOTOR NO"],
            "RENK":  row["RENK"] == undefined ? null : row["RENK"],
            "RUHSAT_SERİ_NO": row["TESCİL BELGE NO"] == undefined ? null : row["TESCİL BELGE NO"],
            "ARAÇ_SAHİBİ" : row["RUHSAT SAHİBİ"] == undefined ? null : row["RUHSAT SAHİBİ"],
            "İLK_TESCİL_TARİHİ": row["İLK TESCİL TARİHİ"] == undefined ? null : row["İLK TESCİL TARİHİ"],
            "ARAC_KM": row["ARAÇ KM"] == undefined ? null : row["ARAÇ KM"],
            "CC_SINIF": row["Motor(lt)"] == undefined ? null : row["Motor(lt)"],
            "KW": row["KW"] == undefined ? null : row["KW"],
            "RENK_ID": row["RENK_RDS_ID"],
            "MUAYENE_BİTİŞ_TARİHİ": row["MUAYENE BİTİŞ TARİHİ"] == undefined ? null : row["MUAYENE BİTİŞ TARİHİ"],
            "ID" : row["ID"],
            "RADIO_CODE": row["RADYO KODU"] == undefined ? null : row["RADYO KODU"],
            "MARKA_ID": row["MARKA_KODU"] == undefined ? null : row["MARKA_KODU"],
            "TİP_ID": row["TİP_KODU"] == undefined ? null : row["TİP_KODU"],
          }
          exportData.push(header);
            
        });

        const idToken = await fetchIdToken();
        const dataHeader ={
          "ENV" :configdata.APP_ENV ,  
          "type": "UPDATE_MULTIPLE_CAR",
          "data": exportData
        }
        console.log(dataHeader);
        const api = configdata.API_BASE + '/cardata';
        const headers = {
          'Authorization': `Bearer ${idToken}`
        }
        axios.post(api,dataHeader,{headers}).then((response) => {
          console.log(response);
          if(response.data != "SUCCESS"){
            messageToggle("Araçlar güncellenemedi , lütfen girilen bilgileri kontrol edin", "error")
          }
          else{
            messageToggle("Araçlar güncellendi ! ", "success")
            setIsLoaded(true);
          }

        })
        .catch((error) => {
          console.log(error);
          messageToggle("Araçlar eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
        });
    }
  
    const validateNeedColumns = {
      "MARKA" : {"type" : "LIST" , "list" : optionsBMP, function : ValidateBrandModelPaket},
      "MODEL" : {"type" : "LIST" , "list" : optionsBMP, function : ValidateBrandModelPaket},
      "PAKET" : {"type" : "LIST" , "list" : optionsBMP, function : ValidateBrandModelPaket},
      "PLAKA": {"type" : "PLAKA", function : validatePlateNumber},
      "KASA" : {"type" : "LIST" , "list" : carBodyStyles, function : validateStandard},
      "VİTES" : {"type" : "LIST" , "list" : transmissions, function : validateStandard},
      "YAKIT TİPİ" : {"type" : "LIST" , "list" : fuel_types, function : validateStandard},
      "CC" : {"type" : "LIST" , "list" : cc_types, function : validateStandard},
      "MODEL YILI" : {"type" : "LIST" , "list" : factory_years, function : validateStandard},
      "TESCİL TARİHİ" : {"type" : "DATE" },
      "MUAYENE BİTİŞ TARİHİ" : {"type" : "DATE" },
      "İLK TESCİL TARİHİ" : {"type" : "DATE" },
    }
  
    const [columns,setColumns] = useState([]);

    function editRowData(input,dataKey){
      if(dataKey.includes("TARİH") && input[dataKey] != null && input[dataKey] != undefined){
        return parseDateFromExcel(input[dataKey]);
      }else{
        return input[dataKey];
      }
    }

    const editFixNeed = (rowObject,text) => {
      if(rowObject['Fix Needed'] === ""){
        rowObject['Fix Needed'] = text;
      }else{
        rowObject['Fix Needed'] += "," +text;
      }
      
    }

    const CustomCellView = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
        
        <View style={{  justifyContent : 'center'  }}>
            <Text style={{textAlign: 'left'}}>{editRowData(rowData,dataKey)}</Text>
        </View>
        
        
      </Table.Cell>
    );



    return (
      <View style={{alignItems: 'center',backgroundColor:'#F2FFFB'}}>
        <View style={{alignItems: 'center' , justifyContent: 'center', marginVertical: 20}}> 
            <FileUploader style= {{width: '90%'}} handleChange={handleFileUpload} name="file" types={["XLSX","XLS"]} />
        </View>

        <View style={{alignItems : 'center' ,marginVertical: 20, width: '95%',backgroundColor: 'white', borderRadius: 12}}>
          <View style={{alignItems : 'center' ,marginVertical : 12}}>
              <Text style={{fontSize: 14 }}> Hatalı Veri Bulunan Araçlar</Text>
          </View>
          <View style={{ flexDirection: 'row', width: '100%',alignItems: 'center',justifyContent: 'center',marginBottom: 10,padding: 20}}>
            <ExportData data ={wrongRows} ></ExportData>
          </View>
          <View style={{width: '95%', height: wrongRows.length > 2 ? 600: 150}}>
            <Table data={wrongRows}height={wrongRows.length > 2 ? 600: 150} bordered cellBordered >
                <Table.Column width={400} resizable >
                  <Table.HeaderCell> Düzeltme Gereken Alanlar </Table.HeaderCell>
                  <Table.Cell dataKey="Fix Needed" />
                </Table.Column>
              {columns.map((colName) => (
                <Table.Column key={colName.key} width= {150} resizable>
                  <Table.HeaderCell>{colName.name}</Table.HeaderCell>
                  <CustomCellView dataKey={colName.name} />
                </Table.Column>
              ))}
              
            </Table>
          </View>
        </View>
     
         
              
        <View style={{alignItems : 'center' ,marginVertical: 20, width: '95%',backgroundColor: 'white', borderRadius: 12}}>
          <Text style={{marginVertical: 20,fontSize: 14}}> Verisinde Sorun Olmayan Araçlar</Text>
          <View style={{ flexDirection: 'row', width: '100%',alignItems: 'center',justifyContent: 'center',marginBottom: 10,padding: 20}}>
                {/* <ExportData data ={correctRows} ></ExportData> */}
                <IconButton disabled={ButtonPressed} style={{width: 200}} onClick={sendData} icon={<Icon><FontAwesomeIcon icon = {'upload'}></FontAwesomeIcon></Icon>} > UPLOAD</IconButton>
                {
                  (!isLoaded && ButtonPressed )&&
                  <View style={{padding: 3, marginHorizontal: 5}}>
                    {/* <Text style={{marginHorizontal: 10, color: 'white' , fontWeight: 'bold' }}> </Text> */}
                    <Loader></Loader>
                  </View>
                }
          </View>
          <View style={{width: '95%', height: correctRows.length > 2 ? 600: 150}}>
              <Table data={correctRows} height={correctRows.length > 2 ? 600: 150} bordered cellBordered>
                {columns.map((colName) => (
                  <Table.Column key={colName.key} width={200} resizable sortable>
                    <Table.HeaderCell>{colName.name}</Table.HeaderCell>
                    <CustomCellView dataKey={colName.name} />
                  </Table.Column>
                ))}
                
              </Table>
          </View>

        </View>
        



        
      </View>
    );
  }
  
  

export default MultipleCarUpdateScreen;