import { Text, View , ScrollView } from 'react-native'
import React from 'react'
import { FlatGrid } from 'react-native-super-grid';
import { Message,Col ,FlexboxGrid, useToaster,IconButton} from 'rsuite';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import "rsuite/dist/rsuite.min.css";


const ShowCurrentPanel = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }
    function reverseDate(input){
        if(input != null && input != undefined){
           
            var result = "";  
    
            var comps = input.split("-");
      
            for(var c = 2 ; c >= 0 ; c--){
              for(var i = 0 ; i < comps[c].length  ; i++){
                result += comps[c][i];
                }
                c != 0 ? result += "-": result += "";
            }
            
            return result;
        }else{
            return "";
        }
       
    }

    var currency_symbol = "₺"
    var formatter = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
  
    });
    var kmformatter = new Intl.NumberFormat('tr-TR', {
      style: 'unit',
      unit: 'kilometer',
    });

    function copyToClipboard(copyText) {
      /* Copy the text inside the text field */
     navigator.clipboard.writeText(copyText);
     /* Alert the copied text */
     messageToggle(" Yazı kopyalandı :  " + copyText,"info");
   }
    function formatAmount(input){
      return formatter.format(input).replace("₺","₺ ")
    }

   
    const Months = ["OCAK","ŞUBAT", "MART" , "NİSAN" , "MAYIS", "HAZİRAN", "TEMMUZ" , "AĞUSTOS", "EYLÜL" , "EKİM" ,"KASIM" ,"ARALIK"]
    const GridItem = ({item}) => {
        return(
  
          <View style = {{ padding: 5,flexDirection :'row', justifyContent: 'space-evenly', backgroundColor: 'white', borderRadius: 7}}>
            <View style={{ padding : 10,justifyContent: 'center',alignItems : 'center', height: '10%', width : '80%'}}>
             <IconButton style={{ height: '10%', width : '100%'}}  icon={<Icon><FontAwesomeIcon icon={'copy'} /></Icon>}  onClick = {() => {copyToClipboard(props.data[item])}}>
              </IconButton>
            </View>

            <View style={{alignItems: 'center' , justifyContent: 'flex-start', height: '30%'}}>
              <Text style = {{fontWeight: 'bold', textAlign: 'center',color: '#B4B4B4' }}>
                {item}
              </Text>
             </View>
            
              <View style={{alignItems: 'center' , height: '60%'}}>
              <ScrollView>
                <Text style = {{ textAlign:'center'}} >
                { (item.includes("TARİH")) ? reverseDate(props.data[item]) : props.data[item] 
                && (item.includes("ARALIĞI") && !item.includes("BAKIM")) ? props.data[item] + " AY" : props.data[item] 
                && (item.includes("BAKIM ARALIĞI")) ? props.data[item] + " KM" : props.data[item] 
                && item.includes("TUTAR") ? formatAmount(props.data[item]): props.data[item]}
                </Text>
              </ScrollView>
              </View>
            
            
             
              
          </View>
        )
  
    }
    const GridItemHorizontal = ({item}) => {
      return(

        <View style={{padding: 5}}>
          <View style = {{ padding: 5,flexDirection :'row',alignItems: 'center', justifyContent: 'flex-start', backgroundColor: 'white', borderRadius: 7 , height: 70}}>
                  
                  <IconButton size="xs"  icon={<Icon><FontAwesomeIcon icon={'copy'} /></Icon>}  onClick = {() => {copyToClipboard(props.data[item])}}>
                  </IconButton>

                  <View style={{ marginHorizontal : 10 ,alignItems: 'flex-start' , justifyContent: 'flex-start', width: '35%'}}>
                    <Text style = {{fontWeight: 'bold', textAlign: 'left',color: '#B4B4B4' }}>
                      {item}
                    </Text>
                  </View>
                  <ScrollView contentContainerStyle={{   flexGrow: 1,height: 50, justifyContent: 'flex-start'}}>
                      <View style={{marginTop : 15, marginBottom: 15 , alignItems: 'flex-start' , justifyContent: 'center' }}>
                        <Text style = {{ textAlign:'left'}} >
                        { (item.includes("TARİH")) ? reverseDate(props.data[item]) : props.data[item] 
                        && (item.includes("ARALIĞI") && !item.includes("BAKIM")) ? props.data[item] + " AY" : props.data[item] 
                        && (item.includes("BAKIM ARALIĞI")) ? kmformatter.format(props.data[item]) : props.data[item] 
                        && item.includes("TUTAR") ? formatAmount(props.data[item]): props.data[item]
                        && item.includes("DEĞER AYI") ? Months[props.data[item] - 1]: props.data[item]
                        && item == "DEĞER" ? formatAmount(props.data[item]): props.data[item]
                        }
                        </Text>
                    </View>
                    </ScrollView>
            </View>
        </View>
       
      )

  }

    return(
        <View style={{marginVertical: 10}}>
              <FlexboxGrid>
              { props.headers.map((item) =>
                  <FlexboxGrid.Item as={Col}  md={item == "NOTLAR" ? 10 : 6 }><GridItemHorizontal item= {item}></GridItemHorizontal> </FlexboxGrid.Item>
              )}
              </FlexboxGrid>
            
        </View>
    )
}

export default ShowCurrentPanel;