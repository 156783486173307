import { Text, View, StyleSheet ,ActivityIndicator, FlatList } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { useToaster, Message, Button, Form ,SelectPicker  ,Checkbox,Pagination,IconButton, Table, List} from 'rsuite';
import ExportData from '../Components/TableManagement/ExportData';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import configdata from '../config/config.json';
import {useUserToken} from "../hooks/useUserToken";
import { useMessage } from '../hooks/useMessage';
import "rsuite/dist/rsuite.min.css";
import CustomText from "../Components/CustomText";

const CarLogShower = ({ route }) => {

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchLogs();
    }, []);


    const {fetchIdToken} = useUserToken();

    const fetchLogs = async () => {
        const api = configdata.API_BASE + '/cardata'
        const idToken = await fetchIdToken();
        const headers = {
            "Authorization" : `Bearer ${idToken}`,
        }

        const body ={
            "ENV":  configdata.APP_ENV,
            "type" : 'GET_CAR_LOGS'
        }
        axios.post (api, body, {headers: headers}).then((response) => {
            console.log(response.data);
            setLogs(response.data);
            setLoading(false);

        }
        ).catch((error) => {
            setError(error.message);
            setLoading(false);
        });
    };


    const renderItem = ({ item }) => (
        <View style={styles.logItem}>
            <CustomText style={styles.logText}><CustomText fontWeight = {800}>Log:</CustomText> {item.LOG}</CustomText>
            <CustomText style={styles.logText}><CustomText fontWeight = {800} style={styles.label}>Type:</CustomText> {item.LOG_TYPE}</CustomText>
            <CustomText style={styles.logText}><CustomText fontWeight = {800} style={styles.label}>Date:</CustomText> {new Date(item.LOG_DATE).toLocaleString()}</CustomText>
            <CustomText style={styles.logText}><CustomText fontWeight = {800} style={styles.label}>PLAKA:</CustomText> {item.PLAKA}</CustomText>
        </View>
    );

    if (loading) {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={styles.errorContainer}>
                <CustomText style={styles.errorText}>{error}</CustomText>
                <Button mode="contained" onPress={fetchLogs} style={styles.retryButton}>
                    Retry
                </Button>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <CustomText style={styles.header}>Araç Logları</CustomText>
            <FlatList
                data={logs}
                keyExtractor={(item) => item.NO.toString()}
                renderItem={renderItem}
                contentContainerStyle={styles.listContainer}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        padding: 16,
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 16,
        textAlign: 'center',
    },
    listContainer: {
        paddingBottom: 16,
    },
    logItem: {
        backgroundColor: '#fff',
        padding: 16,
        marginBottom: 8,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2,
    },
    logText: {
        fontSize: 14,
        marginBottom: 4,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
    },
    errorText: {
        color: 'red',
        fontSize: 16,
        marginBottom: 16,
        textAlign: 'center',
    },
    retryButton: {
        marginTop: 8,
    },
});

export default CarLogShower;
