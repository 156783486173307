import { Text, View  } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { useToaster, Message, Button, Form  ,SelectPicker   ,Checkbox,Pagination,IconButton, Table,Nav } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ExportData from '../Components/TableManagement/ExportData';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import configdata from '../config/config.json';
import 'typeface-roboto';
import { useUserToken } from '../hooks/useUserToken';

/**
 * Represents a screen component for managing global parameters.
 * @param {Object} props - The properties passed to the component.
 */
const GlobalParametersScreen = (props) => {
  const toaster = useToaster();
  function messageToggle(input,type){
      toaster.push(
          <Message showIcon type={type}>
            {input}
          </Message>, { placement: 'topEnd' })
  }
    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [editData,setEditData] = useState(undefined);
    const [refresh,setRefresh] = useState(false);
    const tags = [
        {"label" : "Sigorta Firması" , "value" :  "INSURENCE_FIRM" },
        {"label" : "Acenta Firması" , "value" :  "AGENT_FIRM" },
        {"label" : "HGS Firması" , "value" :  "HGS_FIRM" },
        {"label" : "Bakım Tipi" , "value" :  "MAINTENANCE_TYPE" },
        {"label" : "Egzoz Muayene Aralığı" , "value" :  "EXHAUST_PERIOD" },
        {"label" : "Muayene Tipi" , "value" :  "EXAMINATION_TYPE" },
        {"label" : "Muayene Aralığı" , "value" :  "EXAMINATION_PERIOD" },
        {"label" : "Araç Ruhsat Sahibi" , "value" :  "CAR_OWNER" },
        {"label" : "Renk" , "value" :  "COLOR" },
        {"label" : "Alış Departmanı " , "value" :  "BUY_DEPARTMENT" },
        {"label" : "Edinim Şekli " , "value" :  "CAR_BUY_TYPE" },
        {"label" : "Satın Alım Şekli " , "value" :  "BUY_TYPE" },
        {"label" : "IMM Fiyatı " , "value" :  "IMM_PRICE" },
    ]
    
    function handleModalClose(){
        setModal(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);


    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();
    async function getComponents(){
      const token = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${token}`,
      }
        axios.post(api,{
          "ENV" : configdata.APP_ENV,
            "type" : "GET_GLOBAL_PARAMETERS_ALL",
        },{headers}).then((response)=>{
            console.log(response.data);
            if(response.data.length > 0 && editData == undefined ){
                console.log(response.data[0]);
                setEditData(response.data[0]);
            }

            setData(response.data);
            setRefresh(!refresh);
        }).catch((error)=> {
            console.log(error);
        })
    }


    // function setCurrentActive(input){
    //   for(var i = 0; i< input.length ; i++){
    //     if(input[i]["IS_ACTIVE"]== 1){
    //       setCurrent(input[i]);
    //       break;
    //     }
    //   }
    // }


    function parseTags(inputdata){

      console.log("-------------------------------------------------------------------------------------------");
      const parameterTypes = [];
      inputdata.forEach(item => {
        if(!parameterTypes.includes(item["PARAMETRE TİPİ"])){
          parameterTypes.push(item["PARAMETRE TİPİ"])
        }
      })

      console.log(parameterTypes);
}

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
   
    }
    useEffect(()=> {
        if(editData != undefined){
            console.log(editData);
            setRefresh(!refresh);
            ref_showEdit.current.reset();
        }
      
    },[editData]);

    function setNewNavActive(input){
      setActive(input);
      newPanelref.current.choose(input);
      console.log("ACTIVE : " + input);
    }

    const [active, setActive] = useState('Sigorta Firması');
    const ref_showEdit = useRef();
    const tableHeads= ["NO","PARAMETRE ADI","PARAMETRE TİPİ","DURUM"]
    const labelHeads= ["NO","PARAMETRE ADI","PARAMETRE TİPİ","DURUM"]

    const Navbar = ({ active, onSelect, ...props }) => {
      return (
        <Nav justified {...props} activeKey={active} onSelect={onSelect} style={{ backgroundColor: '#fcfdff', marginBottom: 20 }}>
          {tags.map((value) => (
              <Nav.Item eventKey={value.label} >{value.label}</Nav.Item>
          ))}
          
        </Nav>
      );
    };

    const newPanelref = useRef();

    return(
        <View style={{padding : 10}}>
             
             <NewPanel 
                ref = {newPanelref}
                type={"NEW"}
                tags = {tags}
                reset = {() => {getComponents(); handleModalClose();}}></NewPanel>

              {(editData != null && editData != undefined) &&
              <EditShowPanel
              tags = {tags}
              data = {editData}
              reset = {() => {getComponents(); handleModalClose();}}
              ref= {ref_showEdit}
              ></EditShowPanel>
              
              }

          <Navbar appearance="tabs" active={active} onSelect={setNewNavActive} />
          {tags.map((value) => (
            active == value.label &&
            <View>
              <ComponentListView tags={tags} setchoosen_data={chooseANDEdit} tableName={value.label} tableHead={tableHeads} data={data.filter(item => item["PARAMETRE TİPİ"] == value.value)}></ComponentListView>
            </View>
          ))}

      


      {/* {tags.map((value) => (
        active == value.label &&
        <View>
          <ComponentListView tags={tags} setchoosen_data={chooseANDEdit} tableName={value.label} tableHead={tableHeads} data={data.filter(item => item["PARAMETRE TİPİ"] == value.value)}></ComponentListView>
        </View>
      ))} */}
            {/* <View style={{marginVertical: 10}}>
              <List bordered >
                  {tags.map((value) => (

                    <List.Item size='sm' >
                      <ComponentListView tags={tags} setchoosen_data={chooseANDEdit}  tableName = {value.label} tableHead={tableHeads}  data={data.filter(item => item["PARAMETRE TİPİ"] == value.value)}></ComponentListView>
                    </List.Item>
                  )
                  

                  )}
              </List>
            </View> */}



            {/* <ComponentListView tags={tags} setchoosen_data={chooseANDEdit}  tableName = {"Genel Araç Parametreleri"} tableHead={tableHeads}  data={data}></ComponentListView> */}
            {/* <ShowCurrentPanel data = {current} headers={labelHeads} ></ShowCurrentPanel> */}
            

                        
            <View style={{flexDirection : 'row' , alignItems : 'center'}}>
                {/* <Text style={{marginHorizontal : 10 }} > Döküman Yükle </Text> */}
                {/* <FileLoader carID ={props.data["NO"]} fileCategory ={"TRAFFIC_INSURENCE"} ></FileLoader> */}
                {/* <Button style={{width : 120}}  onClick={() => {if(getS3Keys().length > 0) setModalFILEOpen(true); else messageToggle("Trafik Poliçe Dökümanı bulunamadı", "error")}}> Görüntüle </Button> */}
                {/* <FileViewModal></FileViewModal> */}
                {/* <FolderManagementSystem></FolderManagementSystem> */}
                {/* <FileViewer s3KEY = {props.data["S3 KEY"]}></FileViewer> */}
              </View>
           
        </View>
    )
}


const EditShowPanel = forwardRef((props,ref) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }
    const [activateAddButton,setActiveAddButton] = useState(true);
    // COMBO BOX ARRAYS
   
    // SENT VALUES
    const [value,setValue] = useState(props.data["PARAMETRE ADI"]);
    const [p_type,setPType] = useState(props.data["PARAMETRE TİPİ"]);
    const [parentName,setParentName] = useState(props.data["ÜST KATEGORİ"]);
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [type,setType] = useState("Show");
    
    useImperativeHandle(ref,() => ({
        reset: () => {
            getEditData();
        }
    }))
    
    useEffect(() => {  
        getEditData()
    },[])
   

    function getEditData(){

        setValue(props.data["PARAMETRE ADI"]);
        setPType(props.data["PARAMETRE TİPİ"]);
        setIS_ACTIVE(props.data["DURUM"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    const {fetchIdToken} = useUserToken();
    async function sendData(){
      const token = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${token}`,
      }
      axios.post(api,
        {
          "ENV" : configdata.APP_ENV,
            "type": "UPDATE_GLOBAL_PARAMETER",
            "PARAMETER_VALUE" : value,
            "PARAMETER_TYPE": p_type,
            "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
            "ID" : props.data["NO"]
        },{headers}
      ).then((response) => {
        console.log(response);
        setType("Show");
        props.reset();
        if(response.data["errorMessage"] != null){
            messageToggle("Araç Etiketi eklenemedi, Bağlantınızı kontrol edin", "error")
        }else{
            messageToggle("Araç Etiketi Başarıyla Eklendi", "success")
        }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("Araç Etiketi eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

       
    const item_style = {
    standard: { width : 200 },
    viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }
    const styles = {
        buttonStyle:{
          textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
        }
      }

    function getvaluefromLabelValue(){
      //  console.log(props.tags.find(element => element.value == p_type )["label"]);
       return props.tags.find(element => element.value == p_type )["label"]
    }
    
    return(
        <View style= {{ paddingTop: 5,justifyContent: 'flex-start', alignItems: 'center'}}>
          <View style= {{flexDirection: 'column',width: '100%',justifyContent: 'flex-start',alignItems :'center'}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
              
              <View style={{backgroundColor :'white', borderRadius: 7, padding: 5, width: '100%', alignItems :'center', justifyContent:'center', height: 100}}>
              {
                type == "Show" ? 
                <View style={{width: '60%',alignItems: 'center',justifyContent: 'center'}}>
                    <View style={{width: '100%',flexDirection : 'row',justifyContent: 'space-between'}}>
                        <View style={{flexDirection : 'row',alignItems:'center'}}>
                                <Text  style ={{ textAlign: 'center' , fontWeight: 'bold'}}> Parametre Adı : </Text>
                                <Text style ={{ textAlign: 'center' }}> {value} </Text>
                        </View>
                        <View style={{flexDirection : 'row',alignItems:'center'}}>
                                <Text  style ={{ textAlign: 'center' , fontWeight: 'bold'}}> Parametre Tipi : </Text>
                                <Text style ={{ textAlign: 'center' }}> {getvaluefromLabelValue()} </Text>
                        </View>
                        <Form>
                            <Form.Group>
                            <Button
                                style={styles.buttonStyle}
                                onClick={() => { setType("Edit"); getEditData();}}
                                > Düzenle </Button>
                            </Form.Group>
                        </Form>
                       
                    </View>
                </View> :
                <View style={{width: '100%',flexDirection: 'row'}}>
                    <View style={item_style.viewStyle}>
                        <Form layout='inline'>

                        {/* Etiket İsmi*/}
                        <Form.Group>
                            <Form.ControlLabel> Parametre Adı </Form.ControlLabel>
                            <Form.Control value={value} style={item_style.standard} onChange={ (value) => {setValue(value);}} name= {"parametre İsmi"} /> 
                        </Form.Group>
                        {/* Üst Kategoriler */}
                        <Form.Group>
                            <Form.ControlLabel> Parametre Tipi </Form.ControlLabel>
                            <SelectPicker placement="topStart" value={p_type} style={item_style.standard}  onSelect={(value) => { setPType(value)}} data = {props.tags} ></SelectPicker>
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel> DURUM </Form.ControlLabel>
                            <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                        </Form.Group>
                        <Form.Group>
                            {/* <Form.ControlLabel> DURUM </Form.ControlLabel> */}
                            <Button
                                disabled = {!activateAddButton}
                                style={styles.buttonStyle}
                                onClick={() => { sendData();}}
                                > Kaydet </Button>
                        </Form.Group>
                        <Form.Group>
                            {/* <Form.ControlLabel> DURUM </Form.ControlLabel> */}
                            <Button
                                style={styles.buttonStyle}
                                onClick={() => { setType("Show"); getEditData();}}
                                > İptal Et </Button>
                        </Form.Group>
                        </Form>
                    </View>
                </View>}
              </View>
        
                
                    
                {/* <StandardButton color= '#F20F38' iconName = {"close-circle-outline"} buttonText = {"Cancel"}  onPress = {props.togglePanel}></StandardButton> */}
            </View>
        </View>
    )
});
const NewPanel = forwardRef((props,ref) => {

    useImperativeHandle(ref, () => ({
        choose(input){
          console.log(props.tags);
          console.log(props.tags.find(element => element.label == input ));
          setPType(props.tags.find(element => element.label == input ).value);
        }
    }));



    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }


    const styles = {
      buttonStyle:{
        textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
      }
    }
    const [activateAddButton,setActiveAddButton] = useState(true);
    // COMBO BOX ARRAYS
   
    // SENT VALUES
    const [value,setValue] = useState("");
    const [p_type,setPType] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    useEffect(() => {
       
        if(props.type == "EDIT"){
            getEditData()}
    },[])
   
       useEffect(() => {

          },[value])
     function reset(){
        setValue("");
        setPType("");
        setIS_ACTIVE(true);
    }

    function getEditData(){
        setValue(props.data["PARAMETRE ADI"]);
        setPType(props.data["PARAMETRE TİPİ"]);
        setIS_ACTIVE(props.data["DURUM"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    const {fetchIdToken} = useUserToken();
    async function sendData(){
      const token = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${token}`,
      }
      axios.post(api,
        {
          "ENV" : configdata.APP_ENV,
            "type": "NEW_GLOBAL_PARAMETER",
            "PARAMETER_VALUE" : value,
            "PARAMETER_TYPE": p_type,
        },{headers}
      ).then((response) => {
        console.log(response);
        reset();
        props.reset();
        if(response.data["errorMessage"] != null){
            messageToggle("Araç Parametresi eklenemedi, Bağlantınızı kontrol edin", "error")
        }else{
            messageToggle("Araç Parametresi Başarıyla Eklendi", "success")
        }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("Araç Parametresi eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

       
    const item_style = {
    standard: { width : 200 },
    viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }

    
    return(
        <View style= {{ paddingTop: 5,justifyContent: 'flex-start', alignItems: 'center'}}>
          <View style= {{flexDirection: 'column',width: '100%',justifyContent: 'flex-start',alignItems :'center'}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
            <View style={{width: '100%',flexDirection: 'row'}}>
              <View style={item_style.viewStyle}>
                <Form layout='inline'>

                      {/* Etiket İsmi*/}
                   <Form.Group>
                     <Form.ControlLabel> Parametre Adı </Form.ControlLabel>
                     <Form.Control value={value}  style={item_style.standard} onChange={ (value) => {setValue(value);}} name= {"parametre İsmi"} /> 
                   </Form.Group>
                   {/* Üst Kategoriler */}
                   <Form.Group>
                     <Form.ControlLabel> Parametre Tipi </Form.ControlLabel>
                     <SelectPicker value={p_type}  style={item_style.standard}  onSelect={(value) => { console.log(value); setPType(value)}} data = {props.tags} ></SelectPicker>
                   </Form.Group>
                   <Form.Group>
                     {/* <Form.ControlLabel> Üst Kategori Etiketler </Form.ControlLabel> */}
                     <Button
                  disabled = {!activateAddButton}
                  style={styles.buttonStyle}
                  onClick={() => { sendData();}}
                > Kaydet </Button>
                   </Form.Group>
                </Form>
              </View>
              </View>
              <View style={{height: 50}}></View>
              
            {/* <StandardButton color= '#F20F38' iconName = {"close-circle-outline"} buttonText = {"Cancel"}  onPress = {props.togglePanel}></StandardButton> */}
          </View>
        </View>
    )
});

const ComponentListView = (props) => {

    const [loading, setLoading] = useState(false);
    const [bordered, setBordered] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [refresh , setRefresh] = useState(false);

    const toggle_refresh = () => {
        setRefresh(!refresh)

    }
    function handleModalClose(){
        setPopupActive(false);
    }

    useEffect(()=> {getData()},[props.data]);
  
    const handleChangeLimit = dataKey => {
      setPage(1);
      setLimit(dataKey);

    };
  
    const data = props.data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    const columns = props.tableHead;
    

   
    function viewEdit(input){
      props.setchoosen_data(input);
      console.log(input);
      
    }
    function viewFile(input){
      props.chooseANDShow(input);
      console.log(input);
      
    }
    const ActionCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View style={{ height: '80%'  }}>
            <IconButton icon={<Icon><FontAwesomeIcon icon={"pen-to-square"} /></Icon>}
              onClick={() => {viewEdit(rowData)}}
            />
           
          </View>
        </Table.Cell>
    );

function getvaluefromLabelValue(input){
    if( props.tags.find(element => element.value == input) != undefined ) {
     return  props.tags.find(element => element.value == input)["label"]
    } else return input
}
function parseRowData (rowData,dataKey){
    if(dataKey == "DURUM"){
        if(rowData[dataKey] == 1) return "AKTİF"
        else return "PASİF"
    }
    if(dataKey == "PARAMETRE TİPİ"){
        return getvaluefromLabelValue(rowData[dataKey])
    }else{
        return rowData[dataKey];
    }
}

  const CustomCellView = ({ rowData, dataKey, ...props }) => (
    <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
      <View style={{ height: '80%' , alignItems : 'center'  }}>
          <Text>{parseRowData(rowData,dataKey)}</Text>
      </View>
    </Table.Cell>
);

      const [checkedKeys, setCheckedKeys] = React.useState([]);
      let checked = false;
      let indeterminate = false;
    
      if (checkedKeys.length === data.length) {
        checked = true;
      } else if (checkedKeys.length === 0) {
        checked = false;
      } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
      }
    
      const handleCheckAll = (value, checked) => {
        const keys = checked ? data.map(item => item.id) : [];
        setCheckedKeys(keys);
      };
      const handleCheck = (value, checked) => {
        const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
        setCheckedKeys(keys);
      };
    
      const getData = () => {
        if (sortColumn && sortType) {
         return data.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (typeof x === 'string') {
              x = x.charCodeAt();
            }
            if (typeof y === 'string') {
              y = y.charCodeAt();
            }
            if (sortType === 'asc') {
              return x - y;
            } else {
              return y - x;
            }
          });
        }

          return data;
       
      };

      const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setSortColumn(sortColumn);
          setSortType(sortType);
        }, 500);
      };

      function getWidth(input){

        var max = input.length * 20;
        data.forEach(element => {
          var str = element[input] + "";
          if(str.length * 10 > max){
            max = str.length * 10;
          }
         
        })
          return max;
      }
    
    return ( 

    <View style = {{marginVertical: 2}}>
            <View style = {{ padding : 50 ,marginVertical: 2}}>
                <ExportData data = {data} tableHead = {props.tableHead} tableName={props.tableName}></ExportData>
                <Table
                        autoHeight
                        wordWrap="break-word"
                        loading={loading}
                        showHeader={showHeader}
                        fluid
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        data={data}
                        bordered={bordered}

                        
                    >
                    <Table.Column flexGrow={1} >
                        <Table.HeaderCell> Düzenle </Table.HeaderCell>
                        <ActionCell dataKey= 'Edit'/>
                    </Table.Column>
                    {columns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Table.Column flexGrow={3}  >
                                <Table.HeaderCell>{column}</Table.HeaderCell>
                                <CustomCellView dataKey={column} />
                            </Table.Column>
                        );
                    })}
                    </Table>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={props.data.length}
                        limitOptions={[10, 20]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                        locale={{
                            more: 'Daha',
                            prev: 'Önceki',
                            next: 'Sonraki',
                            first: 'İlk',
                            last: 'Son',
                            limit: '{0} / sayfa',
                            total: 'Toplam Satır: {0}',
                            skip: 'Git {0}'
                        }}
                        />
            </View>
        </View>
    
  
    )
}



export default GlobalParametersScreen;